<template>
    <BT-Blade-Item
        bladeName="customer-template"
        :bladesData="bladesData"
        navigation="customer-templates"
        :onGetSave="getTemplateToSave"
        title="Customer Template">
        <template v-slot="{ item, data }">

            <BT-Field-String
                v-model="item.templateName"
                label="Template Name"
                :isEditing="data.isEditing || data.isNew" />
        
            <BT-Btn-Row v-if="!data.isNew">
                <template v-slot:left>
                    <BT-Sidebar-List
                        :adjustParams="(params, id) => { params.templateID = id; return params; }"
                        leftIcon="mdi-account"
                        label="Customers"
                        manyNavigation="customers"
                        filterProp="SupplyAgreementTemplateID"
                        :singleID="item.id"
                        :itemProperties="['ID','Buyer','SupplyAgreementTemplateID']"
                        singleProp="supplyAgreementTemplateID"
                        manyText="buyer.companyName"
                        :searchProps="['buyer.companyName']" />
                </template>
                <template v-slot:right>
                    <BT-Btn
                        v-if="data.isEditing"
                        icon="mdi-all-inclusive"
                        label="Update Customer Properties"
                        @click="isGlobalSaving = !isGlobalSaving" />
                </template>
            </BT-Btn-Row>

            <BT-Field-Select
                navigation="supplier-payment-terms"
                v-model="item.paymentTermsID"
                :isEditing="data.isEditing || data.isNew"
                item-text="paymentTermsName"
                item-value="id"
                key="1"
                label="Payment Terms"
                :showAction="isGlobalSaving">
                <template v-slot:action>
                    <v-btn v-if="isGlobalSaving" icon @click="toggle('PaymentTermsID')">
                        <v-icon :class="exists('PaymentTermsID') ? 'success--text' : 'error--text'">mdi-check</v-icon>
                    </v-btn>
                </template>
            </BT-Field-Select>

            <BT-Field-Select
                v-model="item.priceTierID"
                itemText="priceTierName"
                label="Price Tier"
                navigation="supplier-price-tiers"
                :showAction="isGlobalSaving"
                :isEditing="data.isEditing || data.isNew">
                <template v-slot:action>
                    <v-btn v-if="isGlobalSaving" icon @click="toggle('PriceTierID')">
                        <v-icon :class="exists('PriceTierID') ? 'success--text' : 'error--text'">mdi-check</v-icon>
                    </v-btn>
                </template>
            </BT-Field-Select>

            <BT-Field-Select
                navigation="automatic-line-items"
                v-model="item.automaticLineItemGroupID"
                itemText="groupName"
                label="Automatic Line Items"
                :showAction="isGlobalSaving"
                :isEditing="data.isEditing || data.isNew">
                <template v-slot:action>
                    <v-btn v-if="isGlobalSaving" icon @click="toggle('AutomaticLineItemGroupID')">
                        <v-icon :class="exists('AutomaticLineItemGroupID') ? 'success--text' : 'error--text'">mdi-check</v-icon>
                    </v-btn>
                </template>
            </BT-Field-Select>
                
            <BT-Field-Checkbox
                v-model="item.useOrderItemRules"
                label="Use Rules"
                :showAction="isGlobalSaving"
                :isEditing="data.isEditing || data.isNew">
                <template v-slot:action>
                    <v-btn v-if="isGlobalSaving" icon @click="toggle('UseOrderItemRules')">
                        <v-icon :class="exists('UseOrderItemRules') ? 'success--text' : 'error--text'">mdi-check</v-icon>
                    </v-btn>
                </template>
            </BT-Field-Checkbox>

            <BT-Field-Select
                navigation="ordering-rules-groups"
                v-model="item.orderItemRulesGroupID"
                :showAction="isGlobalSaving"
                itemText="groupName"
                label="Ordering Rules"
                :isEditing="data.isEditing || data.isNew">
                <template v-slot:action>
                    <v-btn v-if="isGlobalSaving" icon @click="toggle('OrderItemRulesGroupID')">
                        <v-icon :class="exists('OrderItemRulesGroupID') ? 'success--text' : 'error--text'">mdi-check</v-icon>
                    </v-btn>
                </template>
            </BT-Field-Select>

            <BT-Field-Select
                navigation="product-groups"
                v-model="item.productOfferingGroupID"
                :showAction="isGlobalSaving"
                itemText="groupName"
                label="Product Group"
                :isEditing="data.isEditing || data.isNew">
                <template v-slot:action>
                    <v-btn v-if="isGlobalSaving" icon @click="toggle('ProductOfferingGroupID')">
                        <v-icon :class="exists('ProductOfferingGroupID') ? 'success--text' : 'error--text'">mdi-check</v-icon>
                    </v-btn>
                </template>
            </BT-Field-Select>

            <BT-Field-Toggle-Trigger
                v-model="item.orderingArrangement"
                label="Ordering Arrangement"
                :showAction="isGlobalSaving"
                :toggleOptions="[{ text: 'Open', value: 'Open' }, { text: 'Slotted', value: 'Slotted' }]"
                :isEditing="data.isEditing || data.isNew">
                <template v-slot:action>
                    <v-btn v-if="isGlobalSaving" icon @click="toggle('OrderingArrangement')">
                        <v-icon :class="exists('OrderingArrangement') ? 'success--text' : 'error--text'">mdi-check</v-icon>
                    </v-btn>
                </template>
            </BT-Field-Toggle-Trigger>

            <v-row v-if="item.orderingArrangement == 'Slotted'">
                <v-col cols="auto">
                    <BT-Square-Number
                        v-model.number="item.scheduleOrderLimit"
                        :isEditing="data.isEditing || data.isNew"
                        label="Max Future Orders"
                        type="number"
                        width="180" />
                </v-col>
                <v-col v-if="isGlobalSaving">
                    <v-btn icon @click="toggle('ScheduleOrderLimit')">
                        <v-icon :class="exists('ScheduleOrderLimit') ? 'success--text' : 'error--text'">mdi-check</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    <BT-Square-Number
                        v-model.number="item.scheduleDayLimit"
                        :isEditing="data.isEditing || data.isNew"
                        label="Max Future Days"
                        type="number"
                        width="180" />
                </v-col>
                <v-col v-if="isGlobalSaving">
                    <v-btn icon @click="toggle('ScheduleDayLimit')">
                        <v-icon :class="exists('ScheduleDayLimit') ? 'success--text' : 'error--text'">mdi-check</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-slide-y-transition group>
                <!-- <BT-Field-Select
                    v-if="item.orderingArrangement == 'Slotted'"
                    key="1"
                    navigation="order-slot-groups"
                    v-model="item.orderSlotGroupID"
                    :showAction="isGlobalSaving"
                    itemText="groupName"
                    label="Order Slots"
                    :isEditing="data.isEditing || data.isNew">
                    <template v-slot:action>
                        <v-btn v-if="isGlobalSaving" icon @click="toggle('OrderSlotGroupID')">
                            <v-icon :class="exists('OrderSlotGroupID') ? 'success--text' : 'error--text'">mdi-check</v-icon>
                        </v-btn>remv
                    </template>
                </BT-Field-Select> -->

                <BT-Field-Checkbox
                    v-if="item.orderingArrangement == 'Open' || item.orderingArrangement == null"
                    key="2"
                    v-model="item.requireLeadTime"
                    :showAction="isGlobalSaving"
                    label="Order Lead Time"
                    :isEditing="data.isEditing || data.isNew">
                    <template v-slot:action>
                        <v-btn v-if="isGlobalSaving" icon @click="toggle('RequireLeadTime')">
                            <v-icon :class="exists('RequireLeadTime') ? 'success--text' : 'error--text'">mdi-check</v-icon>
                        </v-btn>
                    </template>
                </BT-Field-Checkbox>

                <BT-Field-Number
                    v-if="(item.orderingArrangement == 'Open' || item.orderingArrangement == null) && item.requireLeadTime"
                    key="3"
                    v-model="item.orderLeadHours"
                    :showAction="isGlobalSaving"
                    label="Order Lead Hours"
                    :isEditing="data.isEditing || data.isNew">
                    <template v-slot:action>
                        <v-btn v-if="isGlobalSaving" icon @click="toggle('OrderLeadHours')">
                            <v-icon :class="exists('OrderLeadHours') ? 'success--text' : 'error--text'">mdi-check</v-icon>
                        </v-btn>
                    </template>
                </BT-Field-Number>

                <BT-Field-Checkbox
                    v-if="item.orderingArrangement == 'Open' || item.orderingArrangement == null"
                    key="4"
                    v-model="item.isAvailableWeekends"
                    :showAction="isGlobalSaving"
                    label="Deliver On Weekends"
                    :isEditing="data.isEditing || data.isNew">
                    <template v-slot:action>
                        <v-btn v-if="isGlobalSaving" icon @click="toggle('IsAvailableWeekends')">
                            <v-icon :class="exists('IsAvailableWeekends') ? 'success--text' : 'error--text'">mdi-check</v-icon>
                        </v-btn>
                    </template>
                </BT-Field-Checkbox>

            </v-slide-y-transition>

            <BT-Field-Checkbox
                label="Customer Can Place Orders"
                v-model="item.isCustomerOrderingEnabled"
                :showAction="isGlobalSaving"
                :isEditing="data.isEditing || data.isNew">
                <template v-slot:action>
                    <v-btn v-if="isGlobalSaving" icon @click="toggle('IsCustomerOrderingEnabled')">
                        <v-icon :class="exists('IsCustomerOrderingEnabled') ? 'success--text' : 'error--text'">mdi-check</v-icon>
                    </v-btn>
                </template>
            </BT-Field-Checkbox>

            <BT-Field-Toggle-Trigger
                label="Customer Order Confirmation"
                v-model="item.approvalTrigger"
                :showAction="isGlobalSaving"
                :isEditing="data.isEditing || data.isNew">
                <template v-slot:action>
                    <v-btn v-if="isGlobalSaving" icon @click="toggle('ApprovalTrigger')">
                        <v-icon :class="exists('ApprovalTrigger') ? 'success--text' : 'error--text'">mdi-check</v-icon>
                    </v-btn>
                </template>
            </BT-Field-Toggle-Trigger>

            <BT-Field-Toggle-Trigger
                label="Fulfiller Selection Trigger"
                v-model="item.fulfillerTrigger"
                :showAction="isGlobalSaving"
                :isEditing="data.isEditing || data.isNew">
                <template v-slot:action>
                    <v-btn v-if="isGlobalSaving" icon @click="toggle('FulfillerTrigger')">
                        <v-icon :class="exists('FulfillerTrigger') ? 'success--text' : 'error--text'">mdi-check</v-icon>
                    </v-btn>
                </template>
            </BT-Field-Toggle-Trigger>

            <BT-Field-Toggle-Trigger
                label="Auto Proceed To Stock Orders"
                v-model="item.processTrigger"
                :showAction="isGlobalSaving"
                :isEditing="data.isEditing || data.isNew">
                <template v-slot:action>
                    <v-btn v-if="isGlobalSaving" icon @click="toggle('ProcessTrigger')">
                        <v-icon :class="exists('ProcessTrigger') ? 'success--text' : 'error--text'">mdi-check</v-icon>
                    </v-btn>
                </template>
            </BT-Field-Toggle-Trigger>

            <BT-Field-Checkbox
                label="Customer Must Organize Pickup"
                v-model="item.isRequirePickup"
                :showAction="isGlobalSaving"
                :isEditing="data.isEditing || data.isNew">
                <template v-slot:action>
                    <v-btn v-if="isGlobalSaving" icon @click="toggle('IsRequirePickup')">
                        <v-icon :class="exists('IsRequirePickup') ? 'success--text' : 'error--text'">mdi-check</v-icon>
                    </v-btn>
                </template>
            </BT-Field-Checkbox>
            
<!--STOCK MANAGING AND COURIERING-->


            <BT-Field-Toggle-Trigger
                label="Consignment Trigger"
                v-model="item.consignmentTrigger"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-Toggle-Trigger
                label="Consignment Trigger"
                v-model="item.consignmentTrigger"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-Toggle-Trigger
                defaultValue="FirstInFirstOut"
                :isEditing="data.isEditing || data.isNew"
                label="Stock Provisioning"
                v-model="item.provisionStrategy"
                :toggleOptions="[
                    { text: 'First In First Out', value: 'FirstInFirstOut', icon: 'mdi-refresh-circle' },
                    { text: 'Freshest', value: 'Freshest', icon: 'mdi-sync-circle' }]" />

            <BT-Field-Toggle-Trigger
                label="Provision Trigger"
                v-model="item.provisionTrigger"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-Checkbox
                label="Must Fully Provision"
                v-model="item.mustFullyProvision"
                :isEditing="data.isEditing || data.isNew" />
            
            <BT-Field-Toggle-Trigger
                label="Archive Trigger"
                v-model="item.archiveTrigger"
                :isEditing="data.isEditing || data.isNew" />
            
            <BT-Field-Toggle-Trigger
                label="Dispatch Request Trigger"
                v-model="item.dispatchRequestTrigger"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-Toggle-Trigger
                label="Courier Selector Trigger"
                v-model="item.courierSelectorTrigger"
                :isEditing="data.isEditing || data.isNew"
                :toggleOptions="[
                    { text: 'Customer Or Supplier', value: 'Any', icon: 'mdi-refresh-circle' },
                    { text: 'Customer', value: 'Customer', icon: 'mdi-sync-circle' },
                    { text: 'Supplier', value: 'Supplier', icon: 'mdi-factory' }]" />
            
            <BT-Field-Toggle-Trigger
                label="Courier Selection As Customer"
                v-model="item.courierSelectionTriggerAsCustomer"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-Toggle-Trigger
                label="Courier Selection As Supplier"
                v-model="item.courierSelectionTriggerAsSupplier"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-Checkbox
                label="Is Pickup"
                v-model="item.isPickup"
                :isEditing="data.isEditing || data.isNew" />

            <BT-Field-Toggle-Trigger
                label="Courier Consignment Dispatch Trigger"
                v-model="item.consignmentDispatchTrigger"
                :isEditing="data.isEditing || data.isNew" />



            <!-- <BT-Sidebar-List
                v-if="!data.isNew"
                :adjustParams="(params, id) => { params.templateID = id; return params; }"
                label="Customers"
                manyNavigation="customers"
                filterProp="SupplyAgreementTemplateID"
                :singleID="item.id"
                :itemProperties="['ID','Buyer','SupplyAgreementTemplateID']"
                singleProp="supplyAgreementTemplateID"
                manyText="buyer.companyName"
                :searchProps="['buyer.companyName']" />

            <v-btn 
                v-if="data.isEditing" 
                @click="isGlobalSaving = !isGlobalSaving" 
                block
                class="primary my-2" 
                small>
                Select Properties To Apply To All Attached Customers
            </v-btn> -->
        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Customer-Template-Blade',
    components: {
        BTSidebarList: () => import('~components/BT-Sidebar-List.vue')
    },
    data: function() {
        return {
            isGlobalSaving: false,
            globalPropsToSave: [],
            isLoading: false,
            msg: null,
            panelV: null,
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
    },
    methods: {
        exists(propName) {
            return this.globalPropsToSave.some(x => x == propName);
        },
        getTemplateToSave(bData, item) {
            if (this.isGlobalSaving) {
                item.globalUpdatePropertyName = this.globalPropsToSave.toString();
            }
            else {
                item.globalUpdatePropertyName = null;
            }

            return item;
        },
        toggle(propName) {
            if (this.globalPropsToSave.some(x => x == propName)) {
                this.globalPropsToSave = this.globalPropsToSave.filter(x => x != propName);
            }
            else {
                this.globalPropsToSave.push(propName);
            }
        }
    }
}
 </script>